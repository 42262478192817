import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

const AboutPage = () => (
  <Layout>
    <SEO title="DMCA For Chicken Yard" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">
              Digital Millenium Copyright Act Policy
            </h1>
            <ShortLine />

            <p>
              We respect the intellectual property rights of others just as we
              expect others to respect our rights. Pursuant to Digital
              Millennium Copyright Act, Title 17, United States Code, Section
              512(c), a copyright owner or their agent may submit a takedown
              notice to us via our DMCA Agent listed below. As an internet
              service provider, we are entitled to claim immunity from said
              infringement claims pursuant to the “safe harbor” provisions of
              the DMCA. To submit a good faith infringement claim to us, you
              must submit notice to us that sets forth the following
              information:
            </p>
            <p>
              <b>Notice of Infringement – Claim</b>
            </p>
            <ol>
              <li>
                A physical or electronic signature of the copyright owner (or
                someone authorized to act on behalf of the owner);
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed;
              </li>
              <li>
                Identification of the infringing material to be removed, and
                information reasonably sufficient to permit the service provider
                to locate the material. [Please submit the URL of the page in
                question to assist us in identifying the allegedly offending
                work];
              </li>
              <li>
                Information reasonably sufficient to permit the service provider
                to contact the complaining party including your name, physical
                address, email address, phone number and fax number;
              </li>
              <li>
                A statement that the complaining party has a good faith belief
                that the use of the material is unauthorized by the copyright
                agent; and
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and, under penalty of perjury, that the complaining
                party is authorized to act on behalf of the copyright owner.
              </li>
            </ol>
            <p>
              Title 17 USC §512(f) provides civil damage penalties, including
              costs and attorney fees, against any person who knowingly and
              materially misrepresents certain information in a notification of
              infringement under 17 USC §512(c)(3).
            </p>
            <p>
              Please note that we may share the identity and information in any
              copyright infringement claim we receive with the alleged
              infringer. In submitting a claim, you understand accept and agree
              that your identity and claim may be communicated to the alleged
              infringer.
            </p>
            <p>
              Send all takedown notices to our DMCA Agent listed below.{" "}
              <b>Please send by email for prompt attention.</b>
            </p>
            <p>
              <b>DMCA Agent</b>
            </p>
            <p>Richard A. Chapo, Esq.</p>
            <p>SoCalInternetLawyer.com</p>
            <p>PO Box 373</p>
            <p>Pine Valley, California 91962</p>
            <p>
              <u>
                <a href="mailto:dmcaagent@socalinternetlawyer.com">
                  dmcaagent@socalinternetlawyer.com
                </a>
              </u>
            </p>
            <p>(800) 966-1679</p>
            <p>
              <b>Counter Notification &#8211; Restoration of Material</b>
            </p>
            <p>
              If you have received a notice of material being takedown because
              of a copyright infringement claim, you may provide us with a
              counter notification in an effort to have the material in question
              restored to the site. Said notification must be given in writing
              to our DMCA Agent and must contain substantially the following
              elements pursuant to 17 USC Section 512(g)(3):
            </p>
            <ol>
              <li>Your physical or electronic signature.</li>
              <li>
                A description of the material that has been taken down and the
                original location of the material before it was taken down.
              </li>
              <li>
                A statement under penalty of perjury that you have a good faith
                belief that the material was removed or disabled as a result of
                mistake or misidentification of the material to be removed or
                disabled.
              </li>
              <li>
                Your name, address, and telephone number, and a statement that
                you consent to the jurisdiction of the federal district court
                for the judicial district in which the address is located (or if
                you are outside of the United States, that you consent to
                jurisdiction of any judicial district in which the service
                provider may be found), and that the you will accept service of
                process from the person or company who provided the original
                infringement notification.
              </li>
              <li>Email your counter notice to our DMCA Agent:</li>
            </ol>
            <p>Richard A. Chapo, Esq.</p>
            <p>SoCalInternetLawyer.com</p>
            <p>PO Box 373</p>
            <p>Pine Valley, California 91962</p>
            <p>
              <u>
                <a href="mailto:dmcaagent@socalinternetlawyer.com">
                  dmcaagent@socalinternetlawyer.com
                </a>
              </u>
            </p>
            <p>(800) 966-1679</p>
            <p>
              <b>Repeat Infringer Policy</b>
            </p>
            <p>
              We take copyright infringement very seriously. Pursuant to the
              repeat infringer policy requirements of the Digital Millennium
              Copyright Act, we maintain a list of DMCA notices from copyright
              holders and make a good faith effort to identify any repeat
              infringers. Those that violate our internal repeat infringer
              policy will have their accounts terminated.
            </p>
            <p>Our Business Information</p>
            <p>Chicken Yard</p>
            <p>2715 W. Kettleman Lane Suite 203 #158</p>
            <p>Lodi, CA 95242</p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
